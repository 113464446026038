import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger"
import SmoothScroll from './SmoothScroll';
gsap.registerPlugin(ScrollTrigger)
console.log("hey scrollStuffDesktop.js here!")

let smoothScroller = document.documentElement
// GSAP-SCROLL-TRIGGER init: configuration to be in sync with the scroll behavior changed by SmoothScroll
ScrollTrigger.scrollerProxy(document.body, {
  scrollTop(value) {
    if (arguments.length) {
      smoothScroller.scrollTop = value;
    }
    return document.documentElement.scrollTop;
  }
});
smoothScroller.addEventListener((SmoothScroll.wheelEvent,SmoothScroll.wheel),ScrollTrigger.update);
ScrollTrigger.addEventListener('resize',SmoothScroll.refreshSize,console.log("scrolltrigger resized"));
// END GSAP-SCROLL-TRIGGER END

                      // scroll to top Button
let scrollToTopBtn = document.getElementById("arrowup")
function scrollToTop() {
    smoothScroller.scrollTo({top: 0,behavior: "smooth"})}
scrollToTopBtn.addEventListener("click", scrollToTop);
                      // END scroll to top Button

