import "./scrollStuffDesktop.js";
import gsap from "gsap";
/* import "./rain.js"; */
window.onbeforeunload = function () {
  window.scrollTo(0,0);
};

gsap.set(".header-content", { autoAlpha: 1 });

//get viewport height
let vh = window.innerHeight - 100 + "px";

function t() {
  let e = window.innerHeight,
    t = (e / 100) * 5,
    n = document.querySelectorAll("header h1"),
    o = document.querySelectorAll(".house-head"),
    u = document.querySelectorAll(".house-item-back"),
    c = document.querySelectorAll(".house-item-side"),
    i = document.querySelectorAll(".house-item-layer1"),
    r = document.querySelectorAll(".house-item-layer2"),
    l = document.querySelectorAll(".house-item-layer3"),
    f = document.querySelectorAll(".house-item-layer4"),
    d = document.querySelectorAll(".house-item-layer5"),
    h = "power2.out",
    tl = gsap.timeline({
      paused: true,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".house-head",
        start: "top 90%",
        end: "+=7500",
        markers: false,
        scrub: 2,
      },
    });
  tl.to(n, 2, { y: 100, autoAlpha: 0, ease: h }, 0)
    .to(o, 5, { y: -(e - 1 * t), ease: h }, 0)
    .to(u, 4, { y: -(e - 1 * t), ease: h }, 0.2)
    .to(c, 2.5, { y: -(e - 1 * t), ease: h }, 0)
    .to(i, 4, { y: -(e - 1.5 * t), ease: h }, 1)
    .to(r, 3.8, { y: -(e - 2 * t), ease: h }, 1.2)
    .to(l, 3.5, { y: -(e - 2.5 * t), ease: h }, 1.5)
    .to(f, 3.2, { y: -(e - 3 * t), ease: h }, 1.8)
    .to(d, 3, { y: -(e - 3.5 * t), ease: h }, 2);
}

t();

// number of drops created.
let nbDrop = [10];
let nbDrop2 = [550];
// function to generate a random number range.
let rain = document.querySelector(".rain");
// function to generate drops
function createRain() {
  for (let i = 1; i < nbDrop[0]; i++) {
    let dropLeft = gsap.utils.random(0, 1600);
    let dropTop = gsap.utils.random(-1000, 1400);

    let drop = document.createElement("div");
    drop.className="drop";
    drop.id = "drop"+i;
    rain.appendChild(drop);
    gsap.set("#drop"+i,{left:dropLeft})
    gsap.set("#drop"+i,{top:dropTop})
    
  }
}
// Make it rain
/* createRain();

let tl_rain = gsap.timeline({
  paused: true,
  ease: "power2.out",
  scrollTrigger: {
    trigger: ".room-top",
    start: "top center",
    end: "+=1000",
    markers: true,
    scrub: 1,
  },
});
tl_rain.to(nbDrop, 1, {
    endArray:nbDrop2 ,
    onComplete(){ createRain() },
  }); */




function letitrain(){
  return(
  // See js/ParticleExample.js for actual setup
  new ParticleExample(
    // The image to use
    ["HardRain.png"],

    // Emitter configuration, edit this to change the look
    // of the emitter
    {
      "lifetime": {
        "min": 0.81,
        "max": 0.81
      },
      "frequency": 0.002,
      "emitterLifetime": 0,
      "maxParticles": 1500,
      "addAtBack": false,
      "pos": {
        "x": 0,
        "y": 0
      },
      "behaviors": [
        {
          "type": "alphaStatic",
          "config": {
            "alpha": 0.5            
          }
        },
        {
          "type": "moveSpeedStatic",
          "config": {
            "min": 2000,
            "max": 3000
          }
        },
        {
          "type": "scaleStatic",
          "config": {
            "min": 0.5,
            "max": 1
          }
        },
        {
          "type": "rotationStatic",
          "config": {
            "min": 65,
            "max": 65
          }
        },
        {
          "type": "textureRandom",
          "config": {
            "textures": [
              "images/HardRain.png"
            ]
          }
        },
        {
          "type": "spawnShape",
          "config": {
            "type": "rect",
            "data": {
              "x": -600,
              "y": -800,
              "w": 1900,
              "h": 200
            }
          }
        }
      ]
    })
)}

letitrain();

let tl_blitz_rain = gsap.timeline({
  paused: true,
  ease: "power2.out",
  onComplete: playRandomBlitz,
  scrollTrigger: {
    trigger: ".room-top",
    start: "top center",
    markers: false,
  },
});

tl_blitz_rain.to(".blitz", 0.1, {autoAlpha:1, ease: "power2.out",}, 0)
        .to(".blitz", 0.05, {autoAlpha:0, ease: "power2.out",}, 0.1)
        .to(".blitz, canvas", 0.1, {autoAlpha:1, ease: "power2.out",}, 0.15)
        .to(".blitz", 0.05, {autoAlpha:0, ease: "power2.out",}, 0.25)
        .to(".blitz", 0.1, {autoAlpha:1, ease: "power2.out",}, 0.3)
        .to(".blitz", 0.05, {autoAlpha:0, ease: "power2.out",}, 0.4)

let tl_blitz_short = new gsap.timeline

tl_blitz_short.to(".blitz", 0.1, {autoAlpha:1, ease: "power2.out",}, 0)
        .to(".blitz", 0.05, {autoAlpha:0, ease: "power2.out",}, 0.1)
        .to(".blitz", 0.05, {autoAlpha:1, ease: "power2.out",}, 0.15)
        .to(".blitz", 0.05, {autoAlpha:0, ease: "power2.out",}, 0.2)
        .to(".blitz", 0.05, {autoAlpha:1, ease: "power2.out",}, 0.25)
        .to(".blitz", 0.35, {autoAlpha:0, ease: "power2.out",}, 0.3)

let tl_blitz_long = new gsap.timeline

tl_blitz_long.to(".blitz", 0.1, {autoAlpha:1, ease: "power3.in",}, 0)
  .to(".blitz", 0.05, {autoAlpha:0, ease: "power3.in",}, 0.1)
  .to(".blitz", 0.05, {autoAlpha:1, ease: "power3.in",}, 0.15)
  .to(".blitz", 0.1, {autoAlpha:0, ease: "power3.in",}, 0.25)
  .to(".blitz", 0.1, {autoAlpha:1, ease: "power3.in",}, 0.35)
  .to(".blitz", 0.25, {autoAlpha:0, ease: "power3.in",}, 0.45)

//play randomly tl_blitz_long or tl_blitz_short after random time
function playRandomBlitz(){
  let randomTime = gsap.utils.random(10.5, 30);
  let randomBlitz = gsap.utils.random(0, 1);
  if(randomBlitz == 0){
    tl_blitz_long.restart();
  }else{
    tl_blitz_short.restart();
  }
  setTimeout(playRandomBlitz, randomTime * 1000);
}